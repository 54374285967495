const nameData = {
    //first name output if match not found
    firstNameEmpty: [{
        arab_first: "SIYAN*",
        gender_first: "Neutral",
        german_first: "TONI*",
        id: "EmptyFirstname",
        kurmanji_first: "ARJIN*",
        pakistani_first: "KANISHI*",
        persian_first: "JAVADI*",
        sorani_first: "AJE*",
        turkish_first: "ZERIN*",
        ukrainian_first: "SAVVA*",
    }],
    //last name output if match not found
    lastNameEmpty: [{
        arab_last: "ALGHAMDI*",
        gender_last: "MALE",
        german_last: "MEIER*",
        id: "EmptylastName",
        kurmanji_last: "KOÇLARDAN*",
        pakistani_last: "ABDUL GHANI*",
        persian_last: "AFSHAR*",
        sorani_last: "ABDULMALIK*",
        turkish_last: "YILDIRIM*",
        ukrainian_last: "KYRYCHENKO*",
    }],
    //name output if profanity found
    firstNameBoese: [{
        arab_first: "*@#$%&!",
        gender_first: "*@#$%&!",
        german_first: "*@#$%&!",
        id: "*@#$%&!",
        kurmanji_first: "*@#$%&!",
        pakistani_first: "*@#$%&!",
        persian_first: "*@#$%&!",
        sorani_first: "*@#$%&!",
        turkish_first: "*@#$%&!",
        ukrainian_first: "*@#$%&!",
    }]
}

export default nameData;